import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter  } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store'
import Theme from 'components/template/Theme'
import Layout from 'components/layout'
import history from './history'
import mockServer from './mock'
import appConfig from 'configs/app.config'
import './locales'
import './scss/index.scss'
//toast
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// ** primereact
import "primereact/resources/themes/lara-light-indigo/theme.css";    
// import "flatpickr/dist/themes/material_green.css"; 
    
//core
import "primereact/resources/primereact.min.css";

//icons
// import "primeicons/primeicons.css";  


const environment = process.env.NODE_ENV

if (appConfig.enableMock) {
	mockServer({ environment })
}

function App() {

	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<BrowserRouter history={history}>
					<Theme>
						<Layout />
					</Theme>
					<ToastContainer/>
				</BrowserRouter >
			</PersistGate>
		</Provider>
	)
}

export default App
