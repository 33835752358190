import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

export const initialData = {
  totalRegister: 0,
  totalChecked: 0,
  alertData: {},
  isOpenAlertDialog: false,
}

const dataSlice = createSlice({
  name: "common/data",
  initialState: initialData,
  reducers: {
    setTotalRegisterAndChecked: (state, action) => {
      state.totalRegister = action.payload.register
      state.totalChecked = action.payload.checked
    },
    setAlertData: (state, action) => {
      state.alertData = action.payload
    },
    closeAlertDialog: (state, action) => {
      state.isOpenAlertDialog = false
    },
    openAlertDialog: (state, action) => {
      state.isOpenAlertDialog = true
    },
  },
  extraReducers: {
  },
})

export const { 
  setTotalRegisterAndChecked, closeAlertDialog, openAlertDialog, setAlertData
} = dataSlice.actions

export default dataSlice.reducer
